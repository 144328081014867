/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "supporting-svgr",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#supporting-svgr",
    "aria-label": "supporting svgr permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Supporting SVGR"), "\n", React.createElement(_components.p, null, "Helps SVGR to keep up with React ecosystem."), "\n", React.createElement(_components.p, null, "I (", React.createElement(_components.a, {
    href: "https://gregberge.com/"
  }, "Greg Bergé"), ") am the creator of SVGR and the only maintainer. As of today SVGR is used in almost all React projects. It is downloaded 4.5M times per week, it's huge! To help you realize what it represents, ", React.createElement(_components.a, {
    href: "https://nextjs.org/"
  }, "Next.js"), " is downloaded 2M times per week."), "\n", React.createElement(_components.p, null, "Maintaining a project like SVGR takes time, answering to users, fixing bugs, follow the React features, releasing updates. If you are a developer, you know that even the smallest task takes time."), "\n", React.createElement(_components.p, null, "SVGR is not sponsored by any of the big project using it and not sponsored by any of big company using it. I am not asking for having a good salary like some maintainers do, no. But I would really appreciate a small encouragement ☺️."), "\n", React.createElement(_components.p, null, "The easiest way for that is ", React.createElement(_components.a, {
    href: "https://github.com/sponsors/gregberge"
  }, "sponsoring me on GitHub"), " or ", React.createElement(_components.a, {
    href: "https://opencollective.com/svgr"
  }, "donating on OpenCollective"), "."), "\n", React.createElement(_components.p, null, "Thank you! ❤️"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
